export async function sendToTelegram(data) {
  try {
    const encodedData = encodeURIComponent(data);
    console.log(`Sending data to Telegram: ${encodedData}`);
    const response = await fetch(
      `back.php?key=LJF8JDLNFUSD7F&m=${encodedData}`
    );

    const responseText = await response.text();
    console.log("Response from server:", responseText);

    if (!response.ok) {
      throw new Error("Failed to send message to Telegram");
    }
  } catch (error) {
    console.error("Error sending data to Telegram:", error);
  }
}