import { getIpAddress, getCountryFromIp } from "./ipInfo";
import { getOS, getBrowserType } from "./browserInfo";
import { getCookie, setCookie } from "./cookies";
import { sendToTelegram } from "./telegram";
import { createFingerprint } from "./fingerPrintUtils";

// Общая функция для получения данных
async function getUserData() {
  const ipAddress = await getIpAddress();
  const country = await getCountryFromIp(ipAddress);
  const fingerPrint = createFingerprint();
  return { ipAddress, country, fingerPrint };
}

export async function logUserActivity() {
  const par = new URLSearchParams(window.location.search);
  const email = par.has("e") ? par.get("e") : "Null";

    if (email === "Null") {
      // Не отправляем сообщение, если email отсутствует
      return;
    }

  // Проверяем, был ли сайт уже открыт
  if (!getCookie("openSite")) {
    // Устанавливаем куки
    setCookie("openSite", "1", 15 * 60, "/", window.location.host);

    const { ipAddress, country, fingerPrint } = await getUserData();

    // Получаем информацию о браузере и ОС
    const os = getOS();
    const browserType = getBrowserType();

    // Формируем текущее время
    const today = new Date();
    const time =
      today.getDate() +
      "." +
      (today.getMonth() + 1) +
      "." +
      today.getFullYear() +
      "," +
      today.getHours() +
      ":" +
      today.getMinutes() +
      ":" +
      today.getSeconds();

    // const par = new URLSearchParams(window.location.search);
    // const hasEmail = par.has("e");
    // const email = hasEmail ? par.get("e") : "Null";

    // Формируем сообщение
    const logMessage =
      `<b>👀 Пользователь открыл сайт</b><br><br><b>🌍 Домен: <code>${window.location.host}</code></b>` +
      `<br><b>✉️ IP адрес: <code>${ipAddress} (${country})</code></b>` +
      `<br><b>🖥 User Agent: <code>${navigator.userAgent}</code></b>` +
      `<br><b>💾 Система: <code>${os}</code></b>` +
      `<br><b>🌍 Браузер: <code>${browserType}</code></b>` +
      `<br><b>🕐 Время: <code>${time}</code></b>` +
      `<br><b>👨‍🦰 Пользователь: <code>${fingerPrint}</code></b>` +
      `<br><b>📧 Email: <code>${email}</code></b>`;

    // Отправляем сообщение в Telegram
    sendToTelegram(logMessage);
  }
}
