const ipifyUrl = 'https://api.ipify.org?format=json';
const ipinfoUrl = 'https://ipinfo.io';

/**
 * Получение IP-адреса пользователя
 * @returns {Promise<string>} IP-адрес
 */
export async function getIpAddress() {
  try {
    const response = await fetch(ipifyUrl);
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('Ошибка при получении IP-адреса:', error);
    throw error;
  }
}

/**
 * Получение информации о стране по IP-адресу
 * @param {string} ip - IP-адрес
 * @returns {Promise<string>} Страна
 */
export async function getCountryFromIp(ip) {
  try {
    const response = await fetch(`${ipinfoUrl}/${ip}/geo`);
    const data = await response.json();
    return data.country;
  } catch (error) {
    console.error('Ошибка при получении информации о стране:', error);
    throw error;
  }
}
