// Функция для создания уникального отпечатка пользователя
export function createFingerprint() {
    const canvas = document.createElement("canvas");
    const gl = canvas.getContext("webgl");
  
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneOffset = new Date().getTimezoneOffset();
    const graphicsCard = gl ? gl.getParameter(gl.RENDERER) : "unknown";
  
    const fingerprintData = [
      window.screen.availWidth + "x" + window.screen.availHeight,
      window.screen.colorDepth,
      navigator.userAgent,
      navigator.platform,
      navigator.language,
      navigator.hardwareConcurrency,
      navigator.cookieEnabled,
      timezone,
      timezoneOffset,
      graphicsCard
    ].join("_");
  
    return hashString(fingerprintData);
  }
  
  // Функция для хеширования строки (используется для создания отпечатка)
  function hashString(str) {
    let hash = 0;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }
  